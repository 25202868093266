import { http, sjsgw } from '../http/http'
let sjsgwUrl = sjsgw
if (window.location.href.indexOf('qywx.yunzhangfang.cn') !== -1) {
  sjsgwUrl = ''
}
if (window.location.href.indexOf('172.23.220.101') !== -1) {
  sjsgwUrl = ''
}
if (window.location.href.indexOf('qyunf.com') !== -1) {
  sjsgwUrl = ''
}
if (window.location.href.indexOf('shijiaoshou.cn') !== -1) {
  sjsgwUrl = ''
}
if (window.location.href.indexOf('sjsdz.cn') !== -1) {
  sjsgwUrl = ''
}
if (window.location.href.indexOf('sjscs.cn') !== -1) {
  sjsgwUrl = ''
}

//获取城市
const getAllNodeTree = (params) => {
  return http({
    url: sjsgwUrl +'/api/fintax/application/auto/gszc/out/area/queryAllNodeTree',
    method: 'GET',
    params,
    // headers: {
    //   'Content-Type': 'application/json;charset=utf8',
    // },
  }).then((res) => res.data)
}

//获取行业
const getIndustry = (params) => {
  return http({
    url: sjsgwUrl + '/api/fintax/application/auto/gszc/out/hm/getIndustry',
    method: 'GET',
    params,
    // headers: {
    //   'Content-Type': 'application/json;charset=utf8',
    // },
  }).then((res) => res.data)
}

//获取组织形式
const getConfigOrganizationForm = (params) => {
  return http({
    url: sjsgwUrl + '/api/fintax/application/auto/gszc/out/hm/getConfigOrganizationForm',
    method: 'GET',
    params,
    // headers: {
    //   'Content-Type': 'application/json;charset=utf8',
    // },
  }).then((res) => res.data)
}

//企业核名
const checkQyName = (item) => {
  return http({
    url: sjsgwUrl + '/api/fintax/application/auto/gszc/out/hm/checkQyName',
    method: 'POST',
    data: item,
  }).then((res) => res.data)
}


//组合企业名称中的行政区划
const buildXzqhOptions = (item) => {
  return http({
    url: sjsgwUrl + '/api/fintax/application/auto/gszc/out/hm/buildXzqhOptions',
    method: 'POST',
    data: item,
  }).then((res) => res.data)
}

const getXcxUrlLink = (params) => {
  return http({
    url: sjsgwUrl + '/api/fintax/application/auto/gszc/out/getXcxUrlLink',
    method: 'POST',
    data: params,
    // headers: {
    //   'Content-Type': 'application/json;charset=utf8',
    // },
  }).then((res) => res.data)
}

export default {
  getAllNodeTree,
  getIndustry,
  getConfigOrganizationForm,
  checkQyName,
  buildXzqhOptions,
  getXcxUrlLink
}