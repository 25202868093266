import { http, sjsgw } from '../http/http'
let sjsgwUrl = sjsgw
if (window.location.href.indexOf('qywx.yunzhangfang.cn') !== -1) {
  sjsgwUrl = ''
}
if (window.location.href.indexOf('172.23.220.101') !== -1) {
  sjsgwUrl = ''
}
if (window.location.href.indexOf('qyunf.com') !== -1) {
  sjsgwUrl = ''
}
if (window.location.href.indexOf('shijiaoshou.cn') !== -1) {
  sjsgwUrl = ''
}
if (window.location.href.indexOf('sjsdz.cn') !== -1) {
  sjsgwUrl = ''
}
if (window.location.href.indexOf('sjscs.cn') !== -1) {
  sjsgwUrl = ''
}

const getDemo = (item) => {
  return http({
    url: sjsgwUrl + '/demo',
    method: 'GET',
    params: item,
    // headers: {
    //   'Content-Type': 'application/json;charset=utf8',
    // },
  }).then((res) => res.data)
}

const postDemo = (item) => {
  return http({
    url: sjsgwUrl + '/demo',
    method: 'POST',
    data: item,
  }).then((res) => res.data)
}

//发送加盟提醒消息
const sendJoinMessage = (item) => {
  return http({
    url: sjsgwUrl + '/api/fintax/application/auto/gszc/sjs/gw/sendJoinMessage',
    method: 'GET',
    params: item,
  }).then((res) => res.data)
}
//发送注册提醒消息
const sendJoinMessageV2 = (item) => {
  return http({
    url: sjsgwUrl + '/api/fintax/application/auto/gszc/sjs/gw/sendJoinMessageV2',
    method: 'POST',
    data: item,
  }).then((res) => res.data)
}

export default {
  sendJoinMessage,
  sendJoinMessageV2
}
